import moment from "moment";

const DisplayDateRange = (props) => {
    if (
        moment(props.startDate).format("DD MM YYYY") ===
        moment(props.endDate).format("DD MM YYYY")
    ) {
        return moment(props.startDate).format("DD.MM.YYYY");
    } else {
        return (
            moment(props.startDate).format("DD.MM.YYYY") +
            " - " +
            moment(props.endDate).format("DD.MM.YYYY")
        );
    }
};

export default DisplayDateRange;
