import React, { useEffect, useState } from "react";
import moment from "moment";

import ValueCard from "./Cards/ValueCard";

import { getKpiFromOpenSearch } from "../../scripts/openSearchHandler";
import { formatEventDuration, formatEventNumberValue } from "../../scripts/eventUtils";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";
import SelectedDateRangeHeading from "../Analytics/SelectedDateRangeHeading";

import OldEvents from "./Grids/OldEvents";

import classes from "./EventAnalyticsDashboard.module.css";

const EventAnalyticsDashboard = (props) => {
    const organizationId = props.organizationId;
    // const secret = props.secret;
    // const userId = props.userId;
    const assetId = null;
    const eventId = null;
    const createdBy = null; // no created by stuff yet in event analytics

    const getISODate = (d) => {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();

        const yearStr = "" + year;
        const monthStr = "" + (month >= 10 ? "" + month : "0" + month);
        const dayStr = "" + (day >= 10 ? "" + day : "0" + day);

        return "" + yearStr + "-" + monthStr + "-" + dayStr;
    };

    const startMoment1 = new moment().startOf("month");
    const endMoment1 = new moment().endOf("month");
    const startDate1 = startMoment1.toDate();
    const endDate1 = endMoment1.toDate();

    const d = new Date();
    d.setDate(0); // 0 will result in the last day of the last period
    d.setDate(1); // 1 will result in the first day of the month

    const startMoment2 = new moment(d).startOf("month");
    const endMoment2 = new moment(d).endOf("month");
    const startDate2 = startMoment2.toDate();
    const endDate2 = endMoment2.toDate();

    // // console.log("start of this period", startDate1);
    // // console.log("end of this period", endDate1);
    // // console.log("start of last period", startDate2);
    // // console.log("end of last period", endDate2);

    // // some time zone issues here
    // const startISODate1 = startMoment1.toISOString().split("T")[0];
    // const endISODate1 = endMoment1.toISOString().split("T")[0];
    // const startISODate2 = startMoment2.toISOString().split("T")[0];
    // const endISODate2 = endMoment2.toISOString().split("T")[0];

    // // console.log("start iso date this period", startISODate1);
    // // console.log("end iso date this period", endISODate1);
    // // console.log("start iso date last period", startISODate2);
    // // console.log("end iso date last period", endISODate2);

    // // console.log("props", props);

    const [playerImpressionsThisPeriod, setPlayerImpressionsThisPeriod] = useState(-1);
    const [playsThisPeriod, setPlaysThisPeriod] = useState(-1);
    // const [orgUniqueViewersThisPeriod, setUniqueViewersThisPeriod] = useState(-1);
    const [watchedMinutesThisPeriod, setWatchedMinutesThisPeriod] = useState(-1);
    const [averageViewingTimeThisPeriod, setAverageViewingTimeThisPeriod] = useState(-1);
    // const [createdEventsThisPeriod, setCreatedEventsThisPeriod] = useState(-1);
    // const [publishedEventsThisPeriod, setPublishedEventsThisPeriod] = useState(-1);

    const [playerImpressionsLastPeriod, setPlayerImpressionsLastPeriod] = useState(-1);
    const [playsLastPeriod, setPlaysLastPeriod] = useState(-1);
    // const [orgUniqueViewersLastPeriod, setUniqueViewersLastPeriod] = useState(-1);
    const [watchedMinutesLastPeriod, setWatchedMinutesLastPeriod] = useState(-1);
    const [averageViewingTimeLastPeriod, setAverageViewingTimeLastPeriod] = useState(-1);
    // const [createdEventsLastPeriod, setCreatedEventsLastPeriod] = useState(-1);
    // const [publishedEventsLastPeriod, setPublishedEventsLastPeriod] = useState(-1);

    const [modalDateRangePickerIsOpen, setModalDateRangePickerIsOpen] = useState(false);

    const [startDateThisPeriod, setStartDateThisPeriod] = useState(startDate1);
    const [endDateThisPeriod, setEndDateThisPeriod] = useState(endDate1);
    const [startDateLastPeriod, setStartDateLastPeriod] = useState(startDate2);
    const [endDateLastPeriod, setEndDateLastPeriod] = useState(endDate2);

    const [startISODateThisPeriod, setStartISODateThisPeriod] = useState(getISODate(startDate1));
    const [endISODateThisPeriod, setEndISODateThisPeriod] = useState(getISODate(endDate1));
    const [startISODateLastPeriod, setStartISODateLastPeriod] = useState(getISODate(startDate2));
    const [endISODateLastPeriod, setEndISODateLastPeriod] = useState(getISODate(endDate2));

    const ONE_DAY = 1000 * 60 * 60 * 24;

    const calculateDays = (startDate, endDate) => {
        const delta = endDate - startDate;
        const days = Math.ceil(delta / ONE_DAY);
        return days;
    };

    // Put all start dates and end dates and related stuff into state variables
    const updateDateRanges = (startDate, endDate) => {
        const startDate1 = startDate;
        const endDate1 = endDate;

        // console.log("startDate1", startDate1);
        // console.log("endDate1", endDate1);

        const days = calculateDays(startDate, endDate);
        // console.log("-------------> days", days);
        const milliseconds = days * ONE_DAY;

        // Put an equally long period of time as the last period
        const startDate2 = new Date(startDate1 - milliseconds);
        const endDate2 = new Date(endDate1 - milliseconds);

        // console.log("startDate2", startDate2);
        // console.log("endDate2", endDate2);

        setStartDateThisPeriod(startDate1);
        setEndDateThisPeriod(endDate1);
        setStartISODateThisPeriod(getISODate(startDate1));
        setEndISODateThisPeriod(getISODate(endDate1));

        setStartDateLastPeriod(startDate2);
        setEndDateLastPeriod(endDate2);
        setStartISODateLastPeriod(getISODate(startDate2));
        setEndISODateLastPeriod(getISODate(endDate2));
    };

    // const startMoment = new moment().startOf("month");
    // const endMoment = new moment().endOf("month");
    // const startDate = startMoment.toDate();
    // const endDate = endMoment.toDate();

    //updateDateRanges(startDate, endDate);

    // const getKpiThisMonth = async (name) => {
    //     const start = new moment().startOf("month").toDate();
    //     const end = new moment().endOf("month").toDate();

    //     // console.log("start of this month", start);
    //     // console.log("end of this month", end);
    //     return getKpiFromOpenSearch(name, organizationId, undefined, start, end);
    // };
    // const getKpiPreviousMonth = async (name) => {
    //     const d = new Date();
    //     d.setDate(0); // 0 will result in the last day of the previous month
    //     d.setDate(1); // 1 will result in the first day of the month

    //     const start = new moment(d).startOf("month").toDate();
    //     const end = new moment(d).endOf("month").toDate();

    //     // console.log("start of previous month", start);
    //     // console.log("end of previous month", end);
    //     return getKpiFromOpenSearch(name, organizationId, undefined, start, end);
    // };

    const getKpiThisPeriod = async (name) => {
        // console.log("-----> getKpiThisPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDateThisPeriod, endDateThisPeriod);
    };
    const getKpiLastPeriod = async (name) => {
        // console.log("-----> getKpiLastPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDateLastPeriod, endDateLastPeriod);
    };

    useEffect(() => {
        // this period
        const fetchDataOpi = async () => {
            const v = await getKpiThisPeriod("impressions");
            setPlayerImpressionsThisPeriod(v);
        };
        fetchDataOpi();
        const fetchDataOpAndOwm = async () => {
            const v1 = await getKpiThisPeriod("plays");
            setPlaysThisPeriod(v1);
            const v2 = await getKpiThisPeriod("watchedMinutes");
            setWatchedMinutesThisPeriod(v2);
            // average
            if (v1 > 0) {
                setAverageViewingTimeThisPeriod(Number(v2 / v1));
            } else if (Number(v2) === 0.0) {
                // if watched minutes is 0, just set the average to 0
                setAverageViewingTimeThisPeriod(Number(0.0));
            }
        };
        fetchDataOpAndOwm();
        // const fetchDataOuv = async () => {
        //     const v = await getKpiThisPeriod("orgUniqueViewers");
        //     setUniqueViewersThisPeriod(v);
        // };
        // fetchDataOuv();

        // // created Events this period
        // const fetchDataCatm = async () => {
        //     const resCatm = await getCreatedEvents(organizationId, secret, startISODateThisPeriod, endISODateThisPeriod, createdBy);
        //     const v = calculateNumberOfCreatedEvents(resCatm);
        //     setCreatedEventsThisPeriod(v);
        // };
        // fetchDataCatm();
        // // published Events this period
        // const fetchDataPatm = async () => {
        //     const resPatm = await getPublishedEvents(organizationId, secret, startISODateThisPeriod, endISODateThisPeriod, createdBy);
        //     const v = calculateNumberOfPublishedEvents(resPatm);
        //     setPublishedEventsThisPeriod(v);
        // };
        // fetchDataPatm();

        // last period
        const fetchDataOpipm = async () => {
            const v = await getKpiLastPeriod("impressions");
            setPlayerImpressionsLastPeriod(v);
        };
        fetchDataOpipm();
        const fetchDataOppmAndOwmpm = async () => {
            const v1 = await getKpiLastPeriod("plays");
            setPlaysLastPeriod(v1);
            const v2 = await getKpiLastPeriod("watchedMinutes");
            setWatchedMinutesLastPeriod(v2);

            // average
            if (v1 > 0) {
                setAverageViewingTimeLastPeriod(Number(v2 / v1));
            } else if (Number(v2) === 0.0) {
                // if watched minutes is 0, just set the average to 0
                setAverageViewingTimeLastPeriod(Number(0.0));
            }
        };
        fetchDataOppmAndOwmpm();
        // const fetchDataOuvpm = async () => {
        //     const v = await getKpiLastPeriod("orgUniqueViewers");
        //     setUniqueViewersLastPeriod(v);
        // };
        // fetchDataOuvpm();

        // // created Events last period
        // const fetchDataCapm = async () => {
        //     const resCapm = await getCreatedEvents(organizationId, secret, startISODateLastPeriod, endISODateLastPeriod, createdBy);
        //     const v = calculateNumberOfCreatedEvents(resCapm);
        //     setCreatedEventsLastPeriod(v);
        // };
        // fetchDataCapm();

        // // published Events last period
        // const fetchDataPapm = async () => {
        //     const resPapm = await getPublishedEvents(organizationId, secret, startISODateLastPeriod, endISODateLastPeriod, createdBy);
        //     const v = calculateNumberOfPublishedEvents(resPapm);
        //     setPublishedEventsLastPeriod(v);
        // };
        // fetchDataPapm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, /*secret, createdBy,*/ startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDate, endDate) => {
        // console.log("saveModalHandler startDate, endDate", startDate, endDate);

        updateDateRanges(startDate, endDate);

        setModalDateRangePickerIsOpen(false);
    };

    // const showAllVideosButtonHandler = () => {
    //     setCreatedBy(null);
    //     setShowAllVideos(true);
    // };
    // const showOnlyMyVideosButtonHandler = () => {
    //     setCreatedBy(userId);
    //     setShowAllVideos(false);
    // };

    return (
        <div className={classes.view}>
            {/* <h1>Event Analytics Dashboard</h1> */}

            <div className={classes.actions}>
                {/* <DisplayDateRange startDate={startDate} endDate={endDate} /> */}

                {/* {userId > 0 && !showAllVideos && <Button color="#0a7aad" text="Show analytics for all videos" onClick={showAllVideosButtonHandler} />}
                {userId > 0 && showAllVideos && <Button color="#0a7aad" text="Show analytics for my videos" onClick={showOnlyMyVideosButtonHandler} />} */}

                <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
            </div>

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDateThisPeriod} endDate={endDateThisPeriod} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            <SelectedDateRangeHeading startDate={startDateThisPeriod} endDate={endDateThisPeriod} periodName={"Current period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatEventNumberValue(playerImpressionsThisPeriod)} title={"Player Impressions"} />
                <ValueCard value={formatEventNumberValue(playsThisPeriod)} title={"Plays"} />
                {/* <ValueCard value={orgUniqueViewersThisPeriod} title={"Unique Viewers"} /> */}
                <ValueCard value={formatEventDuration(watchedMinutesThisPeriod)} title={"Time Viewed"} />
                <ValueCard value={formatEventDuration(averageViewingTimeThisPeriod)} title={"Average viewing time"} />
            </div>

            <SelectedDateRangeHeading startDate={startDateLastPeriod} endDate={endDateLastPeriod} periodName={"Previous period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatEventNumberValue(playerImpressionsLastPeriod)} title={"Player Impressions"} />
                <ValueCard value={formatEventNumberValue(playsLastPeriod)} title={"Plays"} />
                {/* <ValueCard value={orgUniqueViewersLastPeriod} title={"Unique Viewers"} /> */}
                <ValueCard value={formatEventDuration(watchedMinutesLastPeriod)} title={"Time Viewed"} />
                <ValueCard value={formatEventDuration(averageViewingTimeLastPeriod)} title={"Average viewing time"} />
            </div>

            <OldEvents organizationId={organizationId} />
        </div>
    );
};

export default EventAnalyticsDashboard;
