import React, { useEffect, useState, useCallback } from "react";

import { useMyContext } from "../../../contexts/StateHolder";
import EventsItem from "./EventsItem";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import moment from "moment";

//import { DateRangePicker } from "react-date-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as classes from "./Events.module.css";
//import { ToastContainer, toast } from "react-toastify";

import ModalDateRangePicker from "../../Analytics/ModalDateRangePicker";
import Backdrop from "../../Analytics/Backdrop";
import DisplayDateRange from "../../Analytics/DisplayDateRange";

import { getEvents } from "../../../scripts/dataHandlers";

Modal.setAppElement(document.body);

export default function AllEvents(props) {
    const { allEvents, setAllEvents } = useMyContext();

    const [startDateValue, setStartDateValue] = useState(new Date());
    const [endDateValue, setEndDateValue] = useState(new Date());
    const [showDateTimeModal, setShowDateTimeModal] = useState(false);
    const [filterDate, setFilterDate] = useState(false);
    const [search, setSearch] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    console.log(props);

    const runFunction = useCallback(async () => {
        try {
            if (allEvents.length === 0) {
                setShowLoader(true);
                const resAll = await getEvents(props.organizationId);

                console.log(`resAll events: `, resAll);

                if (resAll.data.status === "ok") {
                    setAllEvents(resAll.data.events);
                }
                setShowLoader(false);
            }
        } catch (error) {
            console.log(error);
        }
    }, [props.organizationId, allEvents, setAllEvents]);

    useEffect(() => {
        props.organizationId && runFunction();
    }, [props.organizationId, runFunction]);

    const removeAppliedFilter = () => {
        setFilterDate(false);
        runFunction();
    };

    const handleCloseDialog = () => {
        console.log("handle Close Dialog");
        setShowDateTimeModal(!showDateTimeModal);
    };

    const handleConfirm = (startDateValue, endDateValue) => {
        setFilterDate(true);
        setStartDateValue(startDateValue);
        setEndDateValue(endDateValue);
        handleCloseDialog();
    };

    return (
        <>
            <div className={classes.Events_homeLink}>
                <Link to={"/analytics/events/dashboard"}>Home</Link>
            </div>{" "}
            <div className={classes.Events_main} style={{ width: "90%", margin: "0 auto" }}>
                <div className={classes.Events_main_info}>
                    <div className={classes.Events_main_info_header}>
                        <h2>All events</h2>
                    </div>
                    <div className={classes.Events_main_info_search}>
                        <div className={classes.Events_main_info_search_box}>
                            <input type="text" placeholder="Search textfield" onChange={(e) => setSearch(e.target.value)} />
                            <div className={classes.Events_search_icon}>
                                <FontAwesomeIcon icon="search" size="1x" style={{ backgroundColor: "#eceff1" }} />
                            </div>
                        </div>
                        <div className={classes.Events_main_info_search_date}>
                            {filterDate && (
                                <>
                                    <div className={classes.Events_main_info_date_range}>
                                        <DisplayDateRange startDate={startDateValue} endDate={endDateValue} />
                                    </div>
                                    <div className={[classes.Events_main_info_button, classes.Events_main_remove_button].join(" ")}>
                                        <button className={classes.Events_main_info_filter_removeFilter} onClick={removeAppliedFilter}>
                                            Clear Date Range
                                        </button>
                                    </div>
                                </>
                            )}
                            <div className={classes.Events_main_info_button}>
                                <button className={classes.Events_main_info_filter_addFilter} onClick={handleCloseDialog}>
                                    Select Date Range
                                </button>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>
            {showDateTimeModal && <ModalDateRangePicker startDate={startDateValue} endDate={endDateValue} onOk={handleConfirm} onCancel={handleCloseDialog} />}{" "}
            {showDateTimeModal && <Backdrop onCancel={handleCloseDialog} />}
            {showLoader && (
                <div className={classes.Events_loader}>
                    {" "}
                    <Loader type="Oval" color="#394855" height={300} width={300} />
                </div>
            )}{" "}
            {allEvents.length > 0 && (
                <EventsItem
                    allEvents={allEvents
                        .filter((event) =>
                            filterDate ? event.startTime > startDateValue.getTime() && event.startTime < moment(endDateValue.getTime()).endOf("day") : true
                        )
                        .filter((event) => (search.length > 0 ? JSON.stringify(Object.values(event.name)).toLowerCase().includes(search.toLowerCase()) : true))
                        .sort((a, b) => b.startTime - a.startTime)}
                    heading={"All Events"}
                    organizationId={props.organizationId}
                    hideViewAll={true}
                    hideTitle={true}
                    showNoImage={true}
                />
            )}{" "}
        </>
    );
}
