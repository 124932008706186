import React from 'react';

import * as classes from './Events.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EventText(props) {
    // {`${props.description.replace(/^(.{1}[^\s]*).*/, '$1')}
    //     ...`}
    const truncate = (str, max, suffix) =>

        [...str].length < max ?
        str :
        `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(' ')
        )}${suffix}`;

    return (
        <div className={classes.Event}>

      <div className={classes.EventTitle} onClick={props.onClick}>
        {/*<div className={classes.icon}>
          <FontAwesomeIcon icon='info-circle' size='1x' />
        </div>*/}
        {props.title && truncate(props.title, 100, '...')}
      </div>

      <div className={classes.EventInfo}>
        <div className={classes.icon}>
          <FontAwesomeIcon icon='hourglass-start' size='1x' />
        </div>

        {props.info}
      </div>


      <div className={classes.EventDuration}>
        {' '}
        <div className={classes.icon}>
          <FontAwesomeIcon icon='clock' size='1x' />
        </div>
        {props.duration}
      </div>
    </div>
    );
}