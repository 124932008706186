import React from "react";

const myDateToString = (date) => {
    const d = new Date(date);
    let ret = "";
    ret += d.getDate();
    ret += ".";
    ret += d.getMonth() + 1;
    ret += ".";
    ret += d.getYear() + 1900;
    return ret;
};

const myTimeToString = (time) => {
    const t = new Date(time);
    let ret = "";

    let h = "" + t.getHours();
    if (h.length < 2) h = "0" + h;
    ret += h;
    ret += ":";
    let min = "" + t.getMinutes();
    if (min.length < 2) min = "0" + min;
    ret += min;
    return ret;
};

const StartAndDuration = ({ start, duration }) => {
    // console.log("start", start);
    // console.log("duration", duration);

    const end = start + 1000 * duration;

    return (
        <div>
            <p>{myDateToString(start) + ", " + myTimeToString(start) + " - " + myTimeToString(end)}</p>
        </div>
    );
};

export default StartAndDuration;
