import Chart from "react-google-charts";
import classes from "./GoogleChart.module.css";

const GoogleChart = (props) => {
    // Array, for example [{title: "Plays VOD", value: 120}, {title: "Plays LIVE", value: 88}]
    const array = props.array;

    // If every object has some value, return chart. Otherwise return N/A
    if (!array.find((obj) => obj.value < 0) || !array.every((obj) => obj.value <= 0)) {
        return (
            <Chart
                width={"250px"}
                height={"150px"}
                chartType={props.type} // PieChart, ColumnChart
                loader={<div>Loading Chart</div>}
                data={[
                    ["Thing", "Value"],
                    ...array.map((x) => [x.title, x.value >= 0 ? x.value : 0]), // Go through array of title/value objects
                ]}
                options={{
                    colors: ["#0a7aad", "#5faac5"],
                    pieSliceText: "percentage",
                    legend: props.type === "PieChart" ? { position: "labeled", labeledValueText: "value" } : "none", // If chartType is PieChart, use labeled legend
                }}
                rootProps={{ "data-testid": "1" }}
            />
        );
    } else {
        // TODO: show spinner loader ?
        return <div className={classes.NADiv}>{"N/A"}</div>;
    }
};

export default GoogleChart;
