// import { useEffect, useState } from "react";
// import { getSingleEvent } from "../../scripts/dataHandlers";
// import { useMyContext } from "../../contexts/StateHolder";

const EventName = ({ eventName }) => {
    // const [eventName, setEventName] = useState("");

    // const { allEvents } = useMyContext();

    // useEffect(() => {
    //     organizationId && getName();
    // }, [organizationId, eventId]);

    // const getName = async () => {
    //     try {
    //         const ev = allEvents.find((o) => o.eventId == eventId);
    //         if (ev && ev.name && ev.name.en_US) {
    //             setEventName(ev.name.en_US);
    //         } else {
    //             const resEvent = await getSingleEvent(organizationId, eventId);

    //             if (
    //                 resEvent.data.status === "ok" &&
    //                 resEvent.data.event.name &&
    //                 resEvent.data.event.name.en_US
    //             ) {
    //                 setEventName(resEvent.data.event.name.en_US);
    //             } else {
    //                 setEventName("Event " + eventId);
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <div>
            <h1>{eventName}</h1>
        </div>
    );
};

export default EventName;
