import React, { useState } from "react";

// import { MyContext } from "../../contexts/StateHolder";

import { DateRangePicker } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import Button from "./Button";

import classes from "./ModalDateRangePicker.module.css";

const ModalDateRangePicker = (props) => {
    const { startDate, endDate, onOk, onCancel } = props;

    // const {
    //     // modalDateRangePickerIsOpen,
    //     setModalDateRangePickerIsOpen,
    // } = useContext(MyContext);

    // State for holding what range is selected at the moment by this component
    // Get initial values from context.
    const [selectionStartDate, setSelectionStartDate] = useState(
        startDate //new Date()
    );
    const [selectionEndDate, setSelectionEndDate] = useState(
        endDate //new Date()
    );

    const selectionRange = {
        startDate: new Date(selectionStartDate),
        endDate: new Date(selectionEndDate),
        key: "selection",
    };

    const handleDateRangeSelect = (ranges) => {
        // console.log("ranges", ranges);

        const start = ranges.selection.startDate;
        const end = new Date(moment(ranges.selection.endDate.getTime()).endOf("day"));
        /*ranges.selection.endDate;*/

        setSelectionStartDate(start);
        setSelectionEndDate(end);

        // console.log("startDate", start.toString());
        // console.log("unix startDate", start.getTime());
        // console.log("endDate", end.toString());
        // console.log("unix endDate", end.getTime());
        // const delta = end.getTime() - start.getTime();
        // console.log("unix delta", delta);
    };

    // const cancelHandler = () => {
    //     setModalDateRangePickerIsOpen(false);
    // };

    // const confirmHandler = () => {
    //     onOk(selectionStartDate, selectionEndDate);
    //     //setStartDate(selectionStartDate);
    //     //setEndDate(selectionEndDate);
    //     setModalDateRangePickerIsOpen(false);
    // };

    return (
        <div className={classes.modalDateRangePicker}>
            <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleDateRangeSelect}
                // months={1}
                // scroll={{ enabled: true }}
                // direction='vertical'
                // preventSnapRefocus={true}
                // calendarFocus='backwards'
                // showSelectionPreview={true}
                // moveRangeOnFirstSelection={true}
            />
            <div className={classes.actions}>
                <Button color="#66ccdd" text="Cancel" onClick={onCancel} />
                <Button color="#0a7aad" text="OK" onClick={() => onOk(selectionStartDate, selectionEndDate)} />
            </div>
        </div>
    );
};

export default ModalDateRangePicker;
