import React, { useEffect, useState } from "react";

import GoogleChart from "../../Analytics/Charts/GoogleChart";
import Loader from "react-loader-spinner";

import classes from "./ChartCard.module.css";

const ChartCard = (props) => {
    const [showLoader, setShowLoader] = useState(true);

    // console.log("type   : '" + props.type + "'");
    // console.log("value1 : '" + props.value1 + "'");
    // console.log("value2 : '" + props.value2 + "'");

    // instead of N/A show a spinner
    useEffect(() => {
        if ((props.value1 === "N/A" && props.value2 === "N/A") || (props.value1 < 0 && props.value2 < 0)) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [props.value1, props.value2]);

    return (
        <div className={classes.resultItem2}>
            {showLoader && (
                <div className={classes.loader}>
                    {" "}
                    <Loader type="Oval" color="#394855" height={100} width={100} />
                </div>
            )}

            {!showLoader && (
                <GoogleChart
                    type={props.type}
                    array={[
                        { title: props.title1, value: props.value1 },
                        { title: props.title2, value: props.value2 },
                    ]}
                />
            )}

            <div className={classes.resultItemTitle}>{props.title}</div>
        </div>
    );
};

export default ChartCard;
