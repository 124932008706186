import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import HomeLink from "./HomeLink";
import EventNav from "./EventNav";
import EventName from "./EventName";
import StartAndDuration from "../Analytics/StartAndDuration";
import DisplayDateRange from "../Analytics/DisplayDateRange";

import ValueCard from "./Cards/ValueCard";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";

import { useMyContext } from "../../contexts/StateHolder";
import { getSingleEvent } from "../../scripts/dataHandlers";
import {
    //getKpiFromOpenSearch,
    getTotalKpiFromOpenSearch,
    getKpiGraphData,
} from "../../scripts/openSearchHandler";
import { formatEventDuration, formatEventNumberValue, getEventNameFromCurrentEvent } from "../../scripts/eventUtils";

import GenericChart from "../Analytics/Charts/GenericChart";

import classes from "./EventAnalyticsEventDashboard.module.css";

const EventAnalyticsEventDashboard = (props) => {
    const { eventId } = useParams();
    const organizationId = props.organizationId;
    const assetId = null;
    const createdBy = null;

    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        modalDateRangePickerIsOpen,
        setModalDateRangePickerIsOpen,
        currentEvent,
        setCurrentEvent,
    } = useMyContext();

    // const [liveNow, setLiveNow] = useState(-1);
    const [totalUniqueViewers, setTotalUniqueViewers] = useState(-1);
    const [totalUniqueViewersLIVE, setTotalUniqueViewersLIVE] = useState(-1);
    const [totalUniqueViewersVOD, setTotalUniqueViewersVOD] = useState(-1);
    const [totalWatchedMinutes, setTotalWatchedMinutes] = useState(-1);
    // const [totalViews, setTotalViews] = useState(-1);

    const [eventName, setEventName] = useState("");
    const [eventStart, setEventStart] = useState(Date.now());
    const [eventDuration, setEventDuration] = useState(Date.now());

    // const [arrayData, setArrayData] = useState(Date.now());

    // const [data, setData] = useState([]);

    const [watchedMinutesValues, setWatchedMinutesValues] = useState(null);
    // const [uniqueViewersValues, setUniqueViewersValues] = useState(null);
    const [impressionsValues, setImpressionsValues] = useState(null);
    const [playsValues, setPlaysValues] = useState(null);

    // if currentEvent changes
    // - set its name
    // - set the start and end date values
    useEffect(() => {
        setEventName(getEventNameFromCurrentEvent(eventId, currentEvent));

        if (currentEvent.startTime) {
            const t = Date.now();

            // If we set the startDate to be the start time of the event,
            // the KPI for the first day of the event will be filtered out as the
            // value is in OpenSearch with a timestamp at the beginning of the day.

            let start = new Date(currentEvent.startTime);
            let sd = new Date(start.toDateString());

            setStartDate(sd); // context
            setEndDate(new Date(t));

            // state
            setEventStart(currentEvent.startTime);
            if (currentEvent.duration) {
                setEventDuration(currentEvent.duration);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEvent, eventId]);

    const getEvent = async () => {
        try {
            const resEvent = await getSingleEvent(organizationId, eventId);
            if (resEvent.data.status === "ok") {
                setCurrentEvent(resEvent.data.event);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        organizationId && getEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId]);

    // const getKpi = async (name) => {
    //     return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
    // };

    const getTotalKpi = async (name) => {
        return getTotalKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
    };

    // Line 162:15:  Effect callbacks are synchronous to prevent race conditions. Put the async function inside:

    // useEffect(() => {
    //   async function fetchData() {
    //     // You can await here
    //     const response = await MyAPI.getData(someId);
    //     // ...
    //   }
    //   fetchData();
    // }, [someId]); // Or [] if effect doesn't need props or state

    useEffect(() => {
        // const fetchDataLn = async () => {
        //     const ln = await getKpi("liveNow");
        //     setLiveNow(ln);
        // };
        // fetchDataLn();

        const fetchDataTuv = async () => {
            const tuv = await getTotalKpi("totalUniqueViewers");
            setTotalUniqueViewers(tuv);
        };
        fetchDataTuv();

        const fetchDataTuvl = async () => {
            const tuvl = await getTotalKpi("totalUniqueViewersLIVE");
            setTotalUniqueViewersLIVE(tuvl);
        };
        fetchDataTuvl();

        const fetchDataTuvv = async () => {
            const tuvv = await getTotalKpi("totalUniqueViewersVOD");
            setTotalUniqueViewersVOD(tuvv);
        };
        fetchDataTuvv();

        const fetchDataTwm = async () => {
            const wm = await getTotalKpi("totalWatchedMinutes");
            setTotalWatchedMinutes(wm);
        };
        fetchDataTwm();

        // const fetchDataTv = async () => {
        //     const tv = await getKpi("totalViews");
        //     setTotalViews(tv);
        // };
        // fetchDataTv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId, startDate, endDate]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDateValue, endDateValue) => {
        setStartDate(startDateValue);
        setEndDate(endDateValue);
        setModalDateRangePickerIsOpen(false);
    };

    useEffect(() => {
        const getKpiData = async (name) => {
            // const startDate = startDateLastPeriod;
            // const endDate = endDateThisPeriod;
            const data = await getKpiGraphData(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
            return data;
        };

        const fetchGraphData = async () => {
            const watchedMinutes = await getKpiData("watchedMinutes");
            if (watchedMinutes) {
                setWatchedMinutesValues(watchedMinutes);
            }
            // const uniqueViewers = await getKpiData("uniqueViewers");
            // if (uniqueViewers) {
            //     setUniqueViewersValues(uniqueViewers);
            // }
            const impressions = await getKpiData("impressions");
            if (impressions) {
                setImpressionsValues(impressions);
            }
            const plays = await getKpiData("plays");
            if (plays) {
                setPlaysValues(plays);
            }
        };
        fetchGraphData();
    }, [organizationId, createdBy, eventId, startDate, endDate]);

    // https://xd.adobe.com/view/76789862-7430-404b-59fd-1a87dcd18d79-0049/screen/e0bd9a54-beca-42cc-b221-51726155ec0f/

    return (
        <div className={classes.view}>
            <HomeLink />
            <EventName eventName={eventName}></EventName>
            <StartAndDuration start={eventStart} duration={eventDuration} />

            <EventNav eventId={eventId} />

            <h2>Event Dashboard</h2>
            <div className={classes.overviewWrapper14}>
                {/* TODO <ValueCard value={liveNow} title={"Live NOW"} /> */}

                <ValueCard value={formatEventNumberValue(totalUniqueViewers)} title={"Total unique viewers"} />
                <ValueCard value={formatEventNumberValue(totalUniqueViewersLIVE)} title={"Total unique live viewers"} />
                <ValueCard value={formatEventNumberValue(totalUniqueViewersVOD)} title={"Total unique VOD viewers"} />
                <ValueCard value={formatEventDuration(totalWatchedMinutes)} title={"Watched time"} />

                {/* <ValueCard value={""} title={""} /> */}
            </div>

            <h2>Total views</h2>

            <div className={classes.actions}>
                <DisplayDateRange startDate={startDate} endDate={endDate} />

                <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
            </div>

            {/* <p>Total views: {totalViews}</p> */}

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDate} endDate={endDate} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            {/* <h2>Total views</h2> */}

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={null}
                        eventId={eventId}
                        chartType={"plays"}
                        data={[
                            {
                                name: "impressions",
                                values: impressionsValues,
                            },
                            {
                                name: "plays",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutes",
                                values: watchedMinutesValues,
                            },
                        ]}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventAnalyticsEventDashboard;
