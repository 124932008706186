const formatEventNumberValue = (value) => {
    // some error condition
    if (value < 0) {
        return "N/A";
    }

    return value;
};

const formatEventDuration = (minutes) => {
    let retval = "";

    // some error condition
    if (minutes < 0) {
        return "N/A";
    }

    // round to 1 decimal

    if (minutes < 60) {
        minutes = Math.round(10.0 * minutes) / 10.0;
        retval += minutes + " min";
    } else {
        let hours = minutes / 60.0;
        hours = Math.round(10.0 * hours) / 10.0;
        retval += hours + " h";
    }

    return retval;
};

const formatEventPercentage = (percent) => {
    // console.log("percent", percent);

    let retval = "";

    // some error condition
    if (percent < 0) {
        return "N/A";
    }

    // round to 1 decimal
    percent = Math.round(10.0 * percent) / 10.0;
    retval += percent + "%";

    return retval;
};

// returns 100 * val1 / val2 % as string
const formatEventRatioAsPercentage = (val1, val2) => {
    // console.log("val1, val2", val1, val2);

    let retval = "";

    // some error condition
    if (val1 < 0 || val2 <= 0) {
        return "N/A";
    }

    // round to 1 decimal
    let percent = Math.round((1000.0 * val1) / val2) / 10.0;
    retval += percent + "%";

    return retval;
};

// el is the current event
const getEventNameFromCurrentEvent = (eventId, el) => {
    // // console.log("current event", ev);
    if (!el || !el.publicName || !el.name || !el.defaultLanguage) {
        return "Event " + eventId;
    }

    let title = el.publicName[el.defaultLanguage];
    if (!title) {
        title = el.name[el.defaultLanguage];
    }
    if (!title) {
        title = el.publicName["en_US"];
    }
    if (!title) {
        title = el.name["en_US"];
    }
    if (!title) {
        title = "(Event's name is empty)";
    }
    title = title.replace(/<[^>]*>?/gm, "");
    return title;
};

export { formatEventNumberValue, formatEventDuration, formatEventPercentage, formatEventRatioAsPercentage, getEventNameFromCurrentEvent };
