import React, { useState, useContext } from "react";

// First create the context
export const MyContext = React.createContext();

// Then create a StateHolder wrapper component
// to hold the state that the components need.
const StateHolder = (props) => {
    //const [organizationId, setOrganizationId] = useState("");
    let d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    const [startDate, setStartDate] = useState(d);
    const [endDate, setEndDate] = useState(new Date());

    const [modalDateRangePickerIsOpen, setModalDateRangePickerIsOpen] = useState(false);

    // // for axios tests
    // const [kpiFromMenu, setKpiFromMenu] = useState("all-unique-event-viewer-total");

    // store current event
    const [currentEvent, setCurrentEvent] = useState({});

    // store all events
    const [allEvents, setAllEvents] = useState([]);

    // // Chosen tab, frontPage by default
    // const [chosenTab, setChosenTab] = useState("Home");

    // // Stores all movie/serie items, fetched with Axios by using allCategories as groupItemId
    // const [allCategoryItems, setAllCategoryItems] = useState({});

    // // Chosen category
    // const [chosenCategory, setChosenCategory] = useState({});

    // // Stores all categories fetched with Axios
    // const [allCategories, setAllCategories] = useState([]);

    // // Stores all movie/serie items, fetched with Axios by using allCategories as groupItemId
    // const [allItems, setAllItems] = useState([]);

    // // Currently viewed item, used in showing details
    // const [chosenItem, setChosenItem] = useState("");

    // Holds user data
    // const [user, setUser] = useState({});

    // const [vod, setVod] = useState("");

    // // Language
    // const [language, setLanguage] = useState("");

    // // Holds state to toggle loginForm
    // const [viewLoginForm, setViewLoginForm] = useState(false);

    // Holds style configuration for elements
    // const [style, setStyle] = useState({});

    // Organization Id
    const [organizationId, setOrganizationId] = useState("");

    // Secret key
    // const [secret, setSecret] = useState("");

    // // Holds string of prioritized categories
    // const [prioritized, setPrioritized] = useState("");

    // // Holds string of banner groupItemId
    // const [bannerId, setBannerId] = useState("");

    // // Holds string of promo groupItemId
    // const [promoId, setPromoId] = useState("");

    // // Holds string of banner items
    // const [bannerItems, setBannerItems] = useState([]);

    // // Holds string of promo items
    // const [promoItems, setPromoItems] = useState([]);

    // // Holds string of prioritized items
    // const [prioritizedItems, setPrioritizedItems] = useState("");

    // all assets of all the category combined
    // const [allAssets, setAllAssets] = useState(null);

    // // store selected sub-organizattion details
    // const [subOrganizationDetails, setSubOrganizationDetails] = useState(null);

    // singleEvent items
    const [singleEventItem, setSingleEventItem] = useState(null);

    return (
        <MyContext.Provider
            value={{
                currentEvent,
                setCurrentEvent,

                startDate,
                setStartDate,

                endDate,
                setEndDate,

                modalDateRangePickerIsOpen,
                setModalDateRangePickerIsOpen,

                // kpiFromMenu,
                // setKpiFromMenu,

                // subOrganizationDetails,
                // setSubOrganizationDetails,

                // chosenTab,
                // setChosenTab,

                // chosenCategory,
                // setChosenCategory,

                // allCategories,
                // setAllCategories,

                // allItems,
                // setAllItems,

                // chosenItem,
                // setChosenItem,

                // user,
                // setUser,

                // allCategoryItems,
                // setAllCategoryItems,

                // vod,
                // setVod,

                // viewLoginForm,
                // setViewLoginForm,

                // style,
                // setStyle,

                // language,
                // setLanguage,

                // secret,
                // setSecret,

                organizationId,
                setOrganizationId,

                // prioritized,
                // setPrioritized,

                // bannerId,
                // setBannerId,

                // promoId,
                // setPromoId,

                // bannerItems,
                // setBannerItems,

                // promoItems,
                // setPromoItems,

                // prioritizedItems,
                // setPrioritizedItems,

                // allAssets,
                // setAllAssets,

                allEvents,
                setAllEvents,

                singleEventItem,
                setSingleEventItem,
            }}
        >
            {props.children}
        </MyContext.Provider>
    );
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
