import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import HomeLink from "./HomeLink";
import EventNav from "./EventNav";
import EventName from "./EventName";
import StartAndDuration from "../Analytics/StartAndDuration";
import DisplayDateRange from "../Analytics/DisplayDateRange";

import ChartCard from "./Cards/ChartCard";
import ValueCard from "./Cards/ValueCard";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";

import { useMyContext } from "../../contexts/StateHolder";
import { getSingleEvent } from "../../scripts/dataHandlers";
import { getKpiFromOpenSearch, getTotalKpiFromOpenSearch, getKpiGraphData } from "../../scripts/openSearchHandler";
import { formatEventDuration, formatEventNumberValue, getEventNameFromCurrentEvent } from "../../scripts/eventUtils";

import GenericChart from "../Analytics/Charts/GenericChart";

import classes from "./EventAnalyticsEventOverview.module.css";

const EventAnalyticsEventOverview = (props) => {
    const { eventId } = useParams();
    const organizationId = props.organizationId;
    const assetId = null;
    const createdBy = null;

    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        modalDateRangePickerIsOpen,
        setModalDateRangePickerIsOpen,
        currentEvent,
        setCurrentEvent,
    } = useMyContext();

    const [totalUniqueViewers, setTotalUniqueViewers] = useState(-1);
    const [totalImpressions, setTotalImpressions] = useState(-1);
    const [totalPlays, setTotalPlays] = useState(-1);
    const [totalWatchedMinutes, setTotalWatchedMinutes] = useState(-1);
    const [totalAverageViewingTime, setTotalAverageViewingTime] = useState(-1);

    const [averageViewingTime, setAverageViewingTime] = useState(-1);
    const [averageViewingTimeVOD, setAverageViewingTimeVOD] = useState(-1);
    const [averageViewingTimeLIVE, setAverageViewingTimeLIVE] = useState(-1);
    const [impressions, setImpressions] = useState(-1);
    const [impressionsVOD, setImpressionsVOD] = useState(-1);
    const [impressionsLIVE, setImpressionsLIVE] = useState(-1);
    const [plays, setPlays] = useState(-1);
    const [playsVOD, setPlaysVOD] = useState(-1);
    const [playsLIVE, setPlaysLIVE] = useState(-1);
    // const [sumOfDailyViewers, setSumOfDailyViewers] = useState(-1);
    const [watchedMinutes, setWatchedMinutes] = useState(-1);
    const [watchedMinutesVOD, setWatchedMinutesVOD] = useState(-1);
    const [watchedMinutesLIVE, setWatchedMinutesLIVE] = useState(-1);

    const [eventName, setEventName] = useState("");
    const [eventStart, setEventStart] = useState(Date.now());
    const [eventDuration, setEventDuration] = useState(Date.now());

    const [watchedMinutesValues, setWatchedMinutesValues] = useState(null);
    // const [uniqueViewersValues, setUniqueViewersValues] = useState(null);
    const [impressionsValues, setImpressionsValues] = useState(null);
    const [playsValues, setPlaysValues] = useState(null);

    // const [arrayData, setArrayData] = useState(Date.now());

    // const [data, setData] = useState([]);

    // if currentEvent changes
    // - set its name
    // - set the start and end date values
    useEffect(() => {
        setEventName(getEventNameFromCurrentEvent(eventId, currentEvent));

        if (currentEvent.startTime) {
            const t = Date.now();

            // If we set the startDate to be the start time of the event,
            // the KPI for the first day of the event will be filtered out as the
            // value is in OpenSearch with a timestamp at the beginning of the day.

            let start = new Date(currentEvent.startTime);
            let sd = new Date(start.toDateString());

            setStartDate(sd); // context
            setEndDate(new Date(t));

            // state
            setEventStart(currentEvent.startTime);
            if (currentEvent.duration) {
                setEventDuration(currentEvent.duration);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEvent, eventId]);

    const getEvent = async () => {
        try {
            const resEvent = await getSingleEvent(organizationId, eventId);
            if (resEvent.data.status === "ok") {
                setCurrentEvent(resEvent.data.event);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        organizationId && getEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId]);

    const getKpi = async (name) => {
        return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
    };

    const getTotalKpi = async (name) => {
        return getTotalKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
    };

    // Line 162:15:  Effect callbacks are synchronous to prevent race conditions. Put the async function inside:

    // useEffect(() => {
    //   async function fetchData() {
    //     // You can await here
    //     const response = await MyAPI.getData(someId);
    //     // ...
    //   }
    //   fetchData();
    // }, [someId]); // Or [] if effect doesn't need props or state

    // Event Overview
    useEffect(() => {
        const fetchDataTw = async () => {
            const tw = await getTotalKpi("totalUniqueViewers");
            setTotalUniqueViewers(tw);
        };
        fetchDataTw();

        const fetchDataTi = async () => {
            const ti = await getTotalKpi("totalImpressions");
            setTotalImpressions(ti);
        };
        fetchDataTi();

        const fetchDataTpAndTwm = async () => {
            const p = await getTotalKpi("totalPlays");
            setTotalPlays(p);
            const m = await getTotalKpi("totalWatchedMinutes");
            setTotalWatchedMinutes(m);

            // average
            if (p > 0) {
                setTotalAverageViewingTime(Number(m / p));
            } else if (Number(m) === 0.0) {
                // if watched minutes is 0, just set the average to 0
                setTotalAverageViewingTime(Number(0.0));
            }
        };
        fetchDataTpAndTwm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId, startDate, endDate]);

    // Event Details

    useEffect(() => {
        const fetchDataIm = async () => {
            const im = await getKpi("impressions");
            setImpressions(im);
        };
        fetchDataIm();

        const fetchDataImv = async () => {
            const imv = await getKpi("impressionsVOD");
            setImpressionsVOD(imv);
        };
        fetchDataImv();

        const fetchDataIml = async () => {
            const iml = await getKpi("impressionsLIVE");
            setImpressionsLIVE(iml);
        };
        fetchDataIml();

        const fetchDataPlAndWm = async () => {
            const pl = await getKpi("plays");
            setPlays(pl);
            const wm = await getKpi("watchedMinutes");
            setWatchedMinutes(wm);
            if (pl > 0) {
                setAverageViewingTime(Number(wm / pl));
            }
        };
        fetchDataPlAndWm();

        const fetchDataPlvAndWmv = async () => {
            const plv = await getKpi("playsVOD");
            setPlaysVOD(plv);
            const wmv = await getKpi("watchedMinutesVOD");
            setWatchedMinutesVOD(wmv);
            if (plv > 0) {
                setAverageViewingTimeVOD(Number(wmv / plv));
            }
        };
        fetchDataPlvAndWmv();

        const fetchDataPllAndWml = async () => {
            const pll = await getKpi("playsLIVE");
            setPlaysLIVE(pll);
            const wml = await getKpi("watchedMinutesLIVE");
            setWatchedMinutesLIVE(wml);
            if (pll > 0) {
                setAverageViewingTimeLIVE(Number(wml / pll));
            }
        };
        fetchDataPllAndWml();

        // const fetchDataTv = async () => {
        //     const tv = await getKpi("sumOfDailyViewers");
        //     setSumOfDailyViewers(tv);
        // };
        // fetchDataTv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, eventId, startDate, endDate]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDateValue, endDateValue) => {
        setStartDate(startDateValue);
        setEndDate(endDateValue);
        setModalDateRangePickerIsOpen(false);
    };

    useEffect(() => {
        const getKpiData = async (name) => {
            // const startDate = startDateLastPeriod;
            // const endDate = endDateThisPeriod;
            const data = await getKpiGraphData(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
            return data;
        };

        const fetchGraphData = async () => {
            const watchedMinutes = await getKpiData("watchedMinutes");
            if (watchedMinutes) {
                setWatchedMinutesValues(watchedMinutes);
            }
            // const uniqueViewers = await getKpiData("uniqueViewers");
            // if (uniqueViewers) {
            //     setUniqueViewersValues(uniqueViewers);
            // }
            const impressions = await getKpiData("impressions");
            if (impressions) {
                setImpressionsValues(impressions);
            }
            const plays = await getKpiData("plays");
            if (plays) {
                setPlaysValues(plays);
            }
        };
        fetchGraphData();
    }, [organizationId, createdBy, eventId, startDate, endDate]);

    return (
        <div className={classes.view}>
            <HomeLink />
            <EventName eventName={eventName}></EventName>
            <StartAndDuration start={eventStart} duration={eventDuration} />

            <EventNav eventId={eventId} />

            <h2>Event Overview</h2>
            <div className={classes.overviewWrapper15}>
                <ValueCard value={formatEventNumberValue(totalUniqueViewers)} title={"Total unique viewers"} />
                <ValueCard value={formatEventNumberValue(totalImpressions)} title={"Total impressions"} />
                <ValueCard value={formatEventNumberValue(totalPlays)} title={"Total plays"} />
                <ValueCard value={formatEventDuration(totalWatchedMinutes)} title={"Total watched time"} />
                <ValueCard value={formatEventDuration(totalAverageViewingTime)} title={"Average viewing time"} />
            </div>

            <h2>Event Details</h2>

            <div className={classes.actions}>
                <DisplayDateRange startDate={startDate} endDate={endDate} />

                <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
            </div>

            <div className={classes.overviewWrapper14}>
                {/* <ValueCard value={sumOfDailyViewers} title={"Sum of daily viewers"} /> */}

                <ValueCard value={formatEventNumberValue(impressions)} title={"Impressions"} />
                <ValueCard value={formatEventNumberValue(plays)} title={"Plays"} />
                <ValueCard value={formatEventDuration(watchedMinutes)} title={"Watched time"} />
                <ValueCard value={formatEventDuration(averageViewingTime)} title={"Average wiewing time"} />
            </div>

            <div className={classes.overviewWrapper2}>
                <ChartCard
                    type={"PieChart"}
                    title={"Impressions VOD and LIVE"}
                    title1={"VOD"}
                    title2={"LIVE"}
                    value1={formatEventNumberValue(impressionsVOD)}
                    value2={formatEventNumberValue(impressionsLIVE)}
                />
                <ChartCard
                    type={"PieChart"}
                    title={"Plays VOD and LIVE"}
                    title1={"VOD"}
                    title2={"LIVE"}
                    value1={formatEventNumberValue(playsVOD)}
                    value2={formatEventNumberValue(playsLIVE)}
                />
                <ChartCard
                    type={"PieChart"}
                    title={"Watched minutes VOD and LIVE"}
                    title1={"VOD"}
                    title2={"LIVE"}
                    value1={formatEventNumberValue(watchedMinutesVOD)}
                    value2={formatEventNumberValue(watchedMinutesLIVE)}
                />
                <ChartCard
                    type={"ColumnChart"}
                    title={"Average viewing times (min)"}
                    title1={"VOD"}
                    title2={"LIVE"}
                    value1={Number(averageViewingTimeVOD)}
                    value2={Number(averageViewingTimeLIVE)}
                />
            </div>

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDate} endDate={endDate} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            {/* <h2>Unique Viewers</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={null}
                        eventId={eventId}
                        chartType={"uniqueViewers"}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div> */}

            <h2>Views</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={null}
                        eventId={eventId}
                        chartType={"plays"}
                        data={[
                            {
                                name: "impressions",
                                values: impressionsValues,
                            },
                            {
                                name: "plays",
                                values: playsValues,
                            },
                            {
                                name: "watchedMinutes",
                                values: watchedMinutesValues,
                            },
                        ]}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventAnalyticsEventOverview;
