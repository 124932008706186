import React, { useEffect, useState, useMemo } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// import { useMyContext } from "./contexts/StateHolder";

// import settings from "./config_settings.json";
// import styles from "./config_demo.json";

//import { getCategories } from "./scripts/dataHandlers";
//import { getAllEvents } from "./scripts/dataHandlers";

// import { useCookies } from "react-cookie";

import EventAnalyticsDashboard from "./components/EventAnalytics/EventAnalyticsDashboard";

import EventAnalyticsEventDashboard from "./components/EventAnalytics/EventAnalyticsEventDashboard";
import EventAnalyticsEventOverview from "./components/EventAnalytics/EventAnalyticsEventOverview";
import EventAnalyticsEventLive from "./components/EventAnalytics/EventAnalyticsEventLive";

import AllEvents from "./components/EventAnalytics/Grids/OldEventsAll";
import "./shared/FontAwesomeIcon";
//import EventsCategory from "./components/Events/EventsCategory/EventsCategory";

// ES7 React/Redux/GraphQL/React-Native snippets
// Prefix	Method
// imp→	import moduleName from 'module'

// https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

// Router is outside of App, in index.js.
function App() {
    let query = useQuery();

    // Organization Id
    const [organizationId, setOrganizationId] = useState("");

    // // Organization Secret key
    // const [secret, setSecret] = useState("");

    // // Video Creator
    // const [userId, setUserId] = useState("");

    //
    const [initDone, setInitDone] = useState(false);

    // organizationSecret
    // const [cookies, setCookie] = useCookies(["osec"]);

    /**** STEP 1, INIT PROGRAM ****/
    useEffect(() => {
        async function initProgram() {
            try {
                // Access,
                // e.g. http://localhost:3000/?organizationId=36903163&secret=tzJKCtez8f for tiedekulma
                // e.g. http://localhost:3000/?organizationId=6877582&secret=wSSBDaasdD for evaluation1

                const orgId = query.get("organizationId");
                // const secret = query.get("secret");
                // const userId = query.get("userId"); // suite user id (video creator?), if given as argument

                // console.log("initProgram organizationId", orgId);
                // console.log("initProgram secret", secret);
                // console.log("initProgram userId", userId);

                if (orgId) {
                    setOrganizationId(orgId);
                }
                // if (secret) {
                //     setSecret(secret);
                // }
                // if (userId) {
                //     setUserId(userId);
                // }

                setInitDone(true);

                // // Get language from settings
                // const getLanguage = settings.language;

                // // Set language to context
                // setLanguage(getLanguage);

                // Get categories from datahandler
                //const res = await getCategories(
                //    getOrganization.organizationId,
                //    getOrganization.key,
                //    getLanguage
                //);

                //setAllCategories(res);
                // setCookie("osec", secret, { path: "/" });
            } catch (err) {
                console.log(err);
            }
        }

        initProgram();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!initDone) {
        // nothing here yet
        return <div className="App"></div>;
    } else if (!organizationId) {
        console.error("no organization");
        return <div className="App">Not authorized</div>;
    } else {
        return (
            <div className="App">
                <Switch>
                    {
                        <Route path="/" exact>
                            <EventAnalyticsDashboard organizationId={organizationId} />
                        </Route>
                    }

                    {/* Events main page */}
                    <Route path="/analytics/events/dashboard" exact>
                        <EventAnalyticsDashboard organizationId={organizationId} />
                    </Route>
                    <Route path="/analytics/events/dashboard/allEvents" exact>
                        <AllEvents organizationId={organizationId} />
                    </Route>

                    {/* Mikko:
                rakenna routet niin että /analytics/events ja analytics/linear ja /analytics/vod
                /analytics/events/dashboard, /analytics/events/event/1232346/dashboard, /analytics/events/event/1232346/overview */}

                    {/*<Route
                    path="/analytics/events"
                    exact
                    component={EventAnalyticsDashboard}
                >*/}
                    {/* <Route path="/analytics/linear" exact component={Home} />
                    <Route path="/analytics/vod" exact component={Home} /> */}
                    <Route path="/analytics/events/event/:eventId/dashboard" render={() => <EventAnalyticsEventDashboard organizationId={organizationId} />} />
                    <Route path="/analytics/events/event/:eventId/overview" render={() => <EventAnalyticsEventOverview organizationId={organizationId} />} />
                    <Route path="/analytics/events/event/:eventId/live" render={() => <EventAnalyticsEventLive organizationId={organizationId} />} />
                </Switch>
            </div>
        );
    }
}

export default App;
