import React from "react";

import HomeLink from "./HomeLink";

import classes from "./EventAnalyticsEventLive.module.css";

const EventAnalyticsEventLive = (props) => {
    return (
        <div className={classes.view}>
            <HomeLink />
        </div>
    );
};

export default EventAnalyticsEventLive;
