import React, { useEffect, useState } from "react";
import { useMyContext } from "../../../contexts/StateHolder";
import EventsItem from "./EventsItem";
import moment from "moment";
import Loader from "react-loader-spinner";
import * as classes from "./Events.module.css";

import { getEvents } from "../../../scripts/dataHandlers";

export default function OldEvents(props) {
    const { allEvents, setAllEvents } = useMyContext();
    const [showLoader, setShowLoader] = useState(false);

    const startDay = moment().startOf("day");
    const endDay = moment().endOf("day");
    const monthAgo = moment().startOf("day").subtract(30, "days");

    // console.log(props);

    useEffect(() => {
        const runFunction = async () => {
            try {
                // if all events are fetched all ready, why to do it again? So rather just filter events from allEvents
                // if all events are empty then fetch events
                if (allEvents.length === 0) {
                    setShowLoader(true);
                    const resAll = await getEvents(props.organizationId);

                    console.log(`resAll events: `, resAll);

                    if (resAll.data.status === "ok") {
                        setAllEvents(resAll.data.events);
                    }
                    setShowLoader(false);
                }
            } catch (error) {
                console.log(error);
            }
        };

        props.organizationId && runFunction();
    }, [props.organizationId, allEvents, setAllEvents]);

    return (
        // If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly
        <>
            <div className={classes.Events_dashboard_title}>
                {showLoader && (
                    <div className={classes.Events_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
                {allEvents.length > 0 && (
                    <EventsItem
                        allEvents={allEvents
                            .filter((event) => event.startTime > startDay && event.startTime < endDay)
                            .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Today's Events"}
                        organizationId={props.organizationId}
                        hideViewAll={true}
                        hideTitle={false}
                    />
                )}
            </div>{" "}
            <div className={classes.Events_dashboard_title}>
                {" "}
                {allEvents.length > 0 && (
                    <EventsItem
                        allEvents={allEvents
                            .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                            .sort((a, b) => b.startTime - a.startTime)}
                        heading={"This months events"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "}
        </>
    );
}
