import { Link } from "react-router-dom";

import classes from "./EventNav.module.css";

// http://localhost:3000/analytics/events/event/1/dashboard
const EventNav = ({ eventId }) => {
    const baseURL = "/analytics/events/event/" + eventId;

    const linkToDashboard = baseURL + "/dashboard";
    const linkToOverview = baseURL + "/overview";
    // const linkToLive = baseURL + "/live";

    // console.log("EventNav eventId", eventId);

    return (
        <nav className={classes.eventNav}>
            <ul className={classes.eventNavLinks}>
                <Link to={linkToDashboard}>
                    <li>Dashboard</li>
                </Link>
                <Link to={linkToOverview}>
                    <li>Overview</li>
                </Link>
                {/* <Link to={linkToLive}>
                        <li>Live</li>
                    </Link> */}
            </ul>
            <div className={classes.eventNavLabel}></div>
        </nav>
    );
};

export default EventNav;
